import React from 'react';
import { Col } from 'react-bootstrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../../../assets/styles/offer.css';

const OfferRow = ({ num, objective }) => { // Change 'offer' to 'objective'
  return (
    <Col sm={12} md={6} lg={6} xl={4} key={"offer-" + num}>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <div className="single-schedules-inner">
          <h5>{objective.title}</h5>
          <p>{objective.text}</p>
        </div>
      </AnimationOnScroll>
    </Col>
  );
}

export default OfferRow;
