import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { AiOutlineCheckCircle, AiOutlineLineChart, AiOutlineDollarCircle, AiOutlineAreaChart } from 'react-icons/ai';
import ServiceRow from "./_main/services/ServiceRow";
import "../assets/styles/services.css";

const servicesData = [
  {
    icon: <AiOutlineCheckCircle style={{
      width: "145px",
      padding: "5px"
    }} />,
    title: "Full Exit (100% Stake Sale)",
    text: "Looking to make a complete exit from your business? We specialize in helping business owners sell 100% of their company. Our team will guide you through the entire process, from valuation to finding the right buyer, ensuring a smooth and profitable transition. We connect you with serious investors who are ready to take over and grow your business, providing you with the best exit strategy tailored to your needs.",
  },
  {
    icon: <AiOutlineDollarCircle style={{
      width: "140px",
      padding: "5px"
    }} />,
    title: "Partial Stake Sale",
    text: "Need capital but not ready to give up full control of your business? Our partial stake sale service helps you sell a portion of your company, allowing you to bring in new investors while retaining a share of ownership. This option is perfect for businesses looking to raise funds for expansion, pay off debts, or bring in strategic partners to help grow the business.",
  },
  {
    icon: <AiOutlineAreaChart style={{
      width: "140px",
      padding: "5px"
    }} />,
    title: "Business Acquisition Assistance",
    text: "Are you an investor looking to acquire a small or medium-sized business? Our platform connects you with verified business opportunities that match your investment criteria. We provide detailed business listings, financial data, and direct communication channels with sellers, making it easier for you to find and evaluate potential acquisitions. Our team also offers support in negotiations and closing deals, ensuring a seamless buying experience.",
  },
  {
    icon: <AiOutlineLineChart style={{
      width: "140px",
      padding: "5px"
    }} />,
    title: "Valuation Report and Deck Preparation",
    text: "Preparing for a sale or seeking investment? Our valuation report and deck preparation service helps you present your business in the best possible light. We create comprehensive and professional valuation reports, along with investor decks that highlight your company’s strengths, market position, and growth potential. This service ensures you have the necessary tools to attract buyers or investors and negotiate the best terms.",
  }
];

const Services = () => {
  return (
    <div className="section-container bg-theme-color" id="services">
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <Container>
          <div className="section-title text-center">
            <h2 className="">Services</h2>
          </div>

          <Row className="mt-4">
            <Col md={12} className="mx-auto">
              <div className="timeline timeline-one">
                {servicesData.map((service, index) => (
                  <ServiceRow key={index} num={index} service={service} />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </AnimationOnScroll>
    </div>
  );
};

export default Services;
