import React from 'react';
import { Col } from 'react-bootstrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../../../assets/styles/objectives.css';

const ObjectiveRow = ({ num, objective }) => {
  return (
    <Col sm={12} md={6} lg={4} key={"objective-" + num} className='objectives'>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <div className="objective-inner">
          <h5>{objective.title}</h5>
          <p>{objective.text}</p>
        </div>
      </AnimationOnScroll>
    </Col>
  )
}

export default ObjectiveRow