import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Container, Row } from 'react-bootstrap';
import WhyChooseUsRow from './_main/objectives/whychooseusRow';


const objectivesData = [
  {
    title: "Expert Guidance and Support",
    text: "We offer unparalleled expertise and personalized support throughout the buying and selling process. Our team of experienced professionals is dedicated to helping you achieve your business goals, from valuation to negotiation and finalizing the deal.",
  },
  {
    title: "Comprehensive Marketplace",
    text: "Our platform features a diverse range of verified business listings, catering to various industries and investment sizes. Whether you're a buyer looking for a new opportunity or a seller aiming for a successful exit, we connect you with the right people.",
  },
  {
    title: "Secure and Confidential Transactions",
    text: "Your privacy and security are our top priorities. We ensure all transactions are conducted securely and confidentially, protecting your sensitive information and business data throughout the process.",
  },
  {
    title: "Proven Track Record",
    text: "We have a proven track record of successful transactions and satisfied clients. Our platform has facilitated numerous business deals, helping entrepreneurs and investors achieve their objectives efficiently and effectively.",
  },
  {
    title: "Professional Valuation and Presentation",
    text: "We provide professional valuation reports and investor decks that highlight your business’s strengths and growth potential. Our detailed and accurate presentations help attract serious buyers and investors, ensuring you get the best possible terms.",
  },
  {
    title: "Flexible Options for Business Owners",
    text: "Whether you’re looking to sell your business completely, partially, or simply seeking investment, we offer flexible options tailored to your specific needs. Our services are designed to help you find the right buyers or investors and structure deals that work best for you.",
  }
];

const Whychooseus = () => {
  return (
    <div className='section-container' id="choose">
      <AnimationOnScroll animateIn='animate__fadeInUp' animateOnce={true}>
        <Container>
          <div className="section-title text-center">
            <h2>Why Choose us</h2>
          </div>
          <Row gap={4}>
            {
              objectivesData.map((objective, index) => {
                return <WhyChooseUsRow item={objective} num={index+1} key={index} />
              })
            }
          </Row>
        </Container>
      </AnimationOnScroll>
    </div>
  )
}

export default Whychooseus;
