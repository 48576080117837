import React from 'react';
import { Col } from 'react-bootstrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import '../../../assets/styles/whychoose.css';

const WhyChooseUsRow = ({ num, item }) => {
  return (
    <Col sm={12} md={6} lg={6} key={"whychooseus-" + num}>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <div className='whychooseus-card' data-num={num + "."}>
          <div className="whychooseus-card-body">
            <div className='whychooseus-card-title'>{item.title}</div>
            <div className='whychooseus-card-text'>{item.text}</div>
          </div>
        </div>
      </AnimationOnScroll>
    </Col>
  );
}

export default WhyChooseUsRow;
