import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const ServiceRow = ({ num, service }) => {
  return (
    <div className='timeline-item' key={"objective-" + num}>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <span class="icon">
          {service.icon}
        </span>
        <h5 class="my-3">{service.title}</h5>
        <p>{service.text}</p>
      </AnimationOnScroll>
    </div>
  )
}

export default ServiceRow